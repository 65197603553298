<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных портфолио
      </h4>
      <div class="alert-body">
        Не найдено портфолио с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'portfolios', params: { siteId: siteId }}"
        >
          Список портфолио
        </b-link>
        и выбрать другое.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Видимость
                </b-th>
                <b-td>
                  <b-badge
                    pill
                    variant=""
                    class="text-capitalize"
                    :variant="item.is_public ? 'light-success' : 'light-secondary'"
                  >
                    {{ item.is_public ? 'Вкл' : 'Выкл' }}
                  </b-badge>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Slug
                </b-th>
                <b-td>
                  {{ item.slug }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Название
                </b-th>
                <b-td>
                  {{ item.name }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Описание
                </b-th>
                <b-td>
                  {{ item.description }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <image-uploader
            v-model="item.images"
            :company-id="$store.getters['workingMode/company_id']"
            mode="view"
          />
        </b-col>
      </b-row>

      <b-row
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'portfolios.edit', params: { id: item.id, siteId: siteId } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref } from 'vue'

import {
  deletePortfolio as deletePortfolioApi,
  getPortfolio as getPortfolioApi,
} from '@/services/main-api/sites/portfolio'

import useCrudShow from '@/composables/useCrudShow'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
    ImageUploader,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    siteId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const getItem = async () => {
      try {
        const res = await getPortfolioApi({ id: props.id, includes: 'images' })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        is_public: false,
        name: '',
        slug: '',
        description: '',
        images: [],
      },
      getItem,
      deleteEntity: i => deletePortfolioApi(i.id),
      getQuestionTextBeforeDeletion: i => `Портфолио <strong>${i.name}</strong> будет удалено`,
      getTextAfterDeletion: () => 'Портфолио было удалено.',
      runAfterDeletion: async () => {
        await instance.$router.push({ name: 'portfolios', params: { siteId: props.siteId } })
      },
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
